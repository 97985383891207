import {PATH_LICENSES} from './paths';
import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

// ----------------------------------------------------------------------

const LicenseRoutes = {
  path: PATH_LICENSES.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_LICENSES.general.dashboard,
      component: lazy(() => import('../views/pages/Licenses/Main'))
    },
    {
      exact: true,
      path: PATH_LICENSES.general.mvdashboard,
      component: lazy(() => import('../views/pages/Licenses/MvDashboard'))
    },
    {
      exact: true,
      path: PATH_LICENSES.general.vendor,
      component: lazy(() => import('../views/pages/SmartAccountView/Main'))
    },
    {
      exact: true,
      path: PATH_LICENSES.general.settings,
      component: lazy(() => import('../views/pages/Licenses/Settings'))
    },
    {
      exact: true,
      path: PATH_LICENSES.general.licenses,
      component: lazy(() => import('../views/pages/Licenses/Licenses'))
    },
    {
      exact: true,
      path: PATH_LICENSES.general.dashboard,
      component: lazy(() => import('../views/pages/Licenses/Dashboard'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404"/>
    }
  ]
};

export default LicenseRoutes;
