import { PATH_PROVISIONING } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

// ----------------------------------------------------------------------

const ProvisioningRoutes = {
  path: PATH_PROVISIONING.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_PROVISIONING.general.create,
      component: lazy(() => import('../views/pages/Provisioning/Main'))
    },
    {
      exact: true,
      path: PATH_PROVISIONING.general.creator,
      component: lazy(() => import('../views/pages/Provisioning/Creator'))
    },
    {
      exact: true,
      path: PATH_PROVISIONING.general.settings,
      component: lazy(() => import('../views/pages/Provisioning/Settings'))
    },
    {
      exact: true,
      path: PATH_PROVISIONING.general.management,
      component: lazy(() => import('../views/pages/Provisioning/Management'))
    },
    {
      exact: true,
      path: PATH_PROVISIONING.general.view,
      component: lazy(() => import('../views/pages/Provisioning/ProvisioningViews'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default ProvisioningRoutes;
