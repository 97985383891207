import axios, { api2 } from '../../utils/axios';
import AxiosError from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { ErrorDialog, startDialog, closeDialog } from './errorDialog';
import { useHistory } from 'react-router';
import { PATH_GENERAL } from '../../routes/paths';

const initialState = {
  isLoading: false,
  error: false,
  errorDetail: {},
  currentAsset: undefined,
  notifications: null,
  assetsList: [],
  run_id: '',
  types: [],
  data: {},
  softwareTypes: [],
  idSelected: -1,
  changed: 'none',
  detail: '',
  query: null,
  inter: null,
  selectedType: 'all',
  mappedAssets: [],
  scansList: [],
  selectedScan: undefined,
  isLoadingMap: false,
  listOrder: 'asc',
  listOrderBy: 'hostname',
  timePeriod: 'all',
  total: 0,
  totalScans: 0,
  selectedStatus: 'all'
};

// facts { id y status , lo de mas lo saca del asset}

const slice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },
    // RESET ERROR
    resetError(state) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },
    onChangeStatus(state, action) {
      state.isLoading = false;
      state.changed = action.payload;
    },
    setAssetList(state, action) {
      state.isLoading = false;
      state.assetsList = action.payload;
    },

    setData(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },

    setAsset(state, action) {
      state.isLoading = false;
      state.currentAsset = action.payload;
    },

    setTypes(state, action) {
      state.types = action.payload;
    },

    setSelectedType(state, action) {
      state.selectedType = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },

    stopLoading(state) {
      state.isLoading = false;
    },
    setResult(state, action) {
      state.isLoading = false;
      state.detail = action.payload.detail;
      state.assetsList = action.payload.data;
    },
    setRunID(state, action) {
      state.isLoading = false;
      state.run_id = action.payload.run_id;
    },

    setInt(state, action) {
      state.int = action.payload;
    },

    setMappedAssets(state, action) {
      state.mappedAssets = action.payload;
    },

    setScansList(state, action) {
      state.scansList = action.payload;
    },

    setSelectedScan(state, action) {
      state.selectedScan = action.payload;
    },
    setIsLoadingMap(state, action) {
      state.isLoadingMap = action.payload;
    },
    clearInt(state) {
      if (state.int !== null) {
        clearInterval(state.int);
      }
      setInt(null);
    },
    setListOrder(state, action) {
      state.listOrder = action.payload;
    },
    setListOrderBy(state, action) {
      state.listOrderBy = action.payload;
    },
    setTimePeriod(state, action) {
      state.timePeriod = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setTotalScans(state, action) {
      state.totalScans = action.payload;
    },
    setSelectedStatus(state, action) {
      state.selectedStatus = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  stopLoading,
  setAsset,
  setAssetList,
  setDiscovery,
  setIdSelected,
  onChangeStatus,
  setStatus,
  setData,
  clearInt,
  setInt,
  hasError,
  setSelectedType,
  setQuery,
  setListOrder,
  setListOrderBy,
  setTimePeriod,
  setIsLoadingMap,
  setSelectedStatus,
} = slice.actions;

export const assetStatus = {
  DISCOVERING: 'DISCOVERING',
  ACTIVE: 'ACTIVE',
  SCHEDULED: 'SCHEDULED',
  MAINTENANCE: 'MAINTENANCE',
  NEW: 'NEW'
};

export function addAssetToInventory(assetIds) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const ids = Array.isArray(assetIds) ? assetIds : [assetIds];
      await axios.delete(`/api/asset/add-inventory/${ids.join(',')}`);
      dispatch(slice.actions.onChangeStatus('added'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deleteAsset(assetIds) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const ids = Array.isArray(assetIds) ? assetIds : [assetIds];
      await axios.delete(`/api/asset/${ids.join(',')}`);
      dispatch(slice.actions.onChangeStatus('deleted'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsList(query, type, page, size, dir, orderBy, status, scope) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const p = {};
    if (type) p.type = type;
    if (page) p.page = page;
    if (size) p.size = size;
    if (dir) p.dir = dir;
    if (orderBy) p.order_by = orderBy;
    if (status) p.status = status;
    if (scope) p.scope = scope;

    try {
      const response = await axios.get(
        `/api/assets/search/${query ? query : '_all_'}`,
        { params: p }
      );
      const data = response.data.data;
      const total = response.data.total;
      dispatch(slice.actions.setAssetList(data));
      dispatch(slice.actions.setTotal(total));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// saveAsset sirve para un Asset o una lista de Assets
export function saveAsset(body, callOnSubmitted, onFailCredentials = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setIsLoadingMap(true));
    try {
      await axios.post(`/api/asset`, body);
      dispatch(slice.actions.onChangeStatus('created'));
      callOnSubmitted(true);
    } catch (error) {
      let status;
      if (error instanceof AxiosError) {
        status = error.response.status;
      } else {
        status = error.response?.status;
      }
      if (status === 403) {
        let ed = new ErrorDialog(
          'Error discovering Asset, check credentials and network address.',
          'Error creating Asset',
          'Ok'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(ed));
      }
      if (status === 406) {
        let ed = new ErrorDialog(
          `It seems that the required agent is either not currently available or not installed. Please verify the agent's online or install the necessary agent before attempting again.`,
          'Error creating Asset: Agent down.',
          'Ok'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(ed));
      }
      if (status === 401) {
        let ed = new ErrorDialog(
          'Please update your Cisco account. If you do not have a Cisco account, create this asset and mark it as non compliance check.',
          'Attention',
          'Update',
          'Close'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
          if (onFailCredentials) {
            onFailCredentials();
          }
        };
        ed.cancelBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(ed));
      }
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false, status);
    } finally {
      dispatch(slice.actions.setIsLoadingMap(false));
    }
  };
}

export function updateCreatedAsset(data, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.patch(`/api/asset/created/${data.id}`, data);
      dispatch(slice.actions.onChangeStatus('updated'));
      dispatch(slice.actions.setAsset(resp.data));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

export function patchAsset(data, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.patch(`/api/asset/${data.id}`, data);
      dispatch(slice.actions.onChangeStatus('updated'));
      dispatch(slice.actions.setAsset(resp.data));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

export function getAssetById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/asset/${id}`);
      await dispatch(slice.actions.setAsset(resp.data));
      return resp.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rediscoverAsset(ids, onFailCredentials) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.resetError());
    try {
      const assetIds = Array.isArray(ids) ? ids : [ids];
      const resp = await axios.post(
        `/api/asset/retry_discovery/${assetIds.join(',')}`
      );
      const assets = resp.data.data;

      return 'success';
    } catch (error) {
      console.log(error);
      let status;
      if (error instanceof AxiosError) {
        status = error.response.status;
      } else {
        status = error.response?.status;
      }
      if (status === 401) {
        let ed = new ErrorDialog(
          'Please update your Cisco account. Go to Configuration / Settings to add your Cisco credentials.',
          'Attention',
          'Update',
          'Close'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
          if (onFailCredentials) {
            onFailCredentials();
          }
        };
        ed.cancelBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(ed));
      }
      dispatch(slice.actions.hasError(error));
      return 'error';
    }
  };
}

export function getAssetsByVuln(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/byVuln/${id}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByBug(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/byBug/${id}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByCoverage(coverage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/coverage/${coverage}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsLifeCycle() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/life/cycle`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByIssueStatus(status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(
        `/api/assets/search/issues/status/${status}`
      );
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByIssueClosedStatus(status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(
        `/api/assets/search/issues/closed/status/${status}`
      );
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByEox(eox) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/eox/${eox}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByLdos(ldos) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/ldos/${ldos}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByEoS(eos) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/eos/${eos}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByType(t) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/type/${t}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsBySS(ss) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/ss/${ss}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDiscoveryStatusQuery(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/automation/discovery/${id}`);
      dispatch(slice.actions.setData(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVulnerableAssets(id, type, limit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let p = {};
      if (type) p.type = type;
      if (limit) p.limit = limit;

      const resp = await axios.get(`/api/assets/search/vulnerabilities/${id}`, {
        params: p
      });
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBuggyAssets(id, type, limit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let p = {};
      if (type) p.type = type;
      if (limit) p.limit = limit;

      const resp = await axios.get(`/api/assets/search/bugs/${id}`, {
        params: p
      });
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByVulnSeverity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/vuln_severity/${id}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsByBugSeverity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/search/bug_severity/${id}`);
      dispatch(slice.actions.setResult(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssetsTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/assets/types`);
      dispatch(slice.actions.setTypes(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function validateConfig(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.resetError());
    try {
      const assetIds = Array.isArray(ids) ? ids : [ids];
      const resp = await axios.post(
        `/api/asset/validate_conf/${assetIds.join(',')}`
      );
      return resp.data.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function mapAssets(values, onFailCredentials) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoadingMap(true));
    try {
      const resp = await api2.post(`/api/v1/map_assets/`, values);
      if (resp.status === 200) {
        dispatch(slice.actions.setMappedAssets(resp.data.hosts));
        return resp.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        let ed = new ErrorDialog(
          'Please update your Cisco account before performing a Scan.',
          'Attention',
          'Update',
          'Close'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
          if (onFailCredentials) {
            onFailCredentials();
          }
        };
        ed.cancelBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(ed));
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getScans(status, page, pageSize) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let p = {};
      if (status !== 'all') p.status = status;
      if (page) p.page = page;
      if (pageSize) p.pageSize = pageSize;
      // if (date !== 'all') p.CreatedAfter = date

      const resp = await api2.get(`/api/v1/scans`, { params: p });
      if (resp.data) {
        dispatch(slice.actions.setScansList(resp.data.scans));
        dispatch(slice.actions.setTotalScans(resp.data.total));
      }
      return resp.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getScanById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await api2.get(`/api/v1/scans/${id}`);
      if (resp.data) {
        dispatch(slice.actions.setSelectedScan(resp.data));
      }
      return resp.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function deleteScanById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await api2.delete(`/api/v1/scans/${id}`);
      if (resp.status === 200) {
        dispatch(getScans());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function patchScanById(id, body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await api2.patch(`/api/v1/scans/${id}`, body);
      if (resp.status === 200) {
        dispatch(getScans());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function saveStock(values, callOnSubmitted) {
  return async (dispatch) => {
    values['offline'] = true;
    values['status'] = 'NEW';
    console.log(values);
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.post(`/api/asset`, [values]);
      if (resp.status === 201) {
        callOnSubmitted(true, resp.status);
      }
    } catch (error) {
      callOnSubmitted(false, error.response.status);
      dispatch(slice.actions.hasError(error));
    }
  };
}
