import {PATH_DISCOVERY} from './paths';
import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

// ----------------------------------------------------------------------

const DiscoveryRoutes = {
  path: PATH_DISCOVERY.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DISCOVERY.general.portal,
      component: lazy(() => import('../views/pages/Discovery/Main'))
    },
    {
      exact: true,
      path: PATH_DISCOVERY.general.scan,
      component: lazy(() => import('../views/pages/Discovery/Main/Scan'))
    },
    {
      exact: true,
      path: PATH_DISCOVERY.general.device,
      component: lazy(() => import('../views/pages/Discovery/Main/Device'))
    },
    {
      exact: true,
      path: PATH_DISCOVERY.general.controller,
      component: lazy(() => import('../views/pages/Discovery/Main/Controller'))
    },
    {
      exact: true,
      path: PATH_DISCOVERY.general.controller_config,
      component: lazy(() => import('../views/pages/Discovery/Main/Controller/config'))
    },
    {
      exact: true,
      path: PATH_DISCOVERY.general.device_management,
      component: lazy(() => import('../views/pages/Discovery/Device'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404"/>
    }
  ]
};

export default DiscoveryRoutes;
